/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

html {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

body {
  color: #171717 !important;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
}

@media screen and (min-width: 600px) {
  .sv_main {
    font-family: 'Open Sans';
    margin: 40px !important;
  }

  .intersections {
    width: 35% !important;
    height: 35% !important;
  }

  table th:not(:first-child), table td:not(:first-child) {
    text-align: center;
    width: 11%;
  }

  .panel-heading {
    margin-top: -20px;
  }
}

@media screen and (max-width: 600px) {
  .intersections {
    width: 100% !important;
    height: 100% !important;
    text-align: center;
  }
 
}

option {
  width: 50px !important;
  word-wrap: break-word;
  text-overflow: inherit;
  white-space: normal;
}

.sv_select_wrapper > select {
    line-height: 1em !important;
}


.sv_next_btn {
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
  height: 50px;
  width: 80px;
  font-size: 18px !important;
  outline: none !important;
}

.sv_complete_btn {
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
  height: 50px;
  width: 100px;
  font-size: 18px !important;
  outline: none !important;
}

.sv_main .sv-logo--right {
  display: inline-block;
  vertical-align: top;
  margin-left: 4em;
  float: right;
  margin-right: -5em;
}

.sv_header__text {
  width: 100% !important;
  margin-top: -40px;
}

.survey-title {
  font-size: 28px !important;
  color: #1ab394;
  font-weight: bold;
}

.page-title {
  font-size: 24px !important;
  color: #1ab394;
  font-weight: bold;
  margin-top: -10px;
}

.question-title {
  font-size: 18px !important;
  line-height: 1.3;
}

.question-description {
  font-size: 18px !important;
}

.question-content {
  text-align: left;
}

.form-control {
  font-size: 14px !important;
  width: 100% !important;
}



.dropdown-menu {
  width: 170px !important;
}

.dropdown-menu .dropdown-item {
  white-space: normal !important;
}

table th {
  text-align: center !important;
}

.sv_qstn label.sv_q_m_label {
  position: inherit !important;
  display: contents !important;
}

input[type="radio"] {
  width:1.3em !important;
  height:1.3em !important;
  vertical-align: middle;
}

/* label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
label > input[type="radio"]:checked + * {
  color: #1ab394;
  vertical-align: text-bottom;
}
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(#1ab394 0%, #1ab394 40%, transparent 50%, transparent);
  border-color: #1ab394;
} */

.panel-footer {
  background-color: #fff !important;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.sv_q_imgsel {
  text-align: center !important;
  padding: 2px !important;
}

.sv_qstn .sv_q_imgsel label > div {
  padding: 10px !important;
}

/* .sv_qstn .sv_q_imgsel label > div > img, .sv_qstn .sv_q_imgsel label > div > embed {
  display: block !important;
  padding: 2px !important;
  object-fit: cover !important;
  width: 100% !important;
} */

/* .sv_q_image {
    margin-left: 40% !important;
    margin-bottom: -1%;
    margin-top: -1%;
} */

li {
  line-height: 1.3;
}

.panel-body.card-block.mt-4 > div:first-child {
    display: none !important;
}

.sv_qstn {
  overflow: inherit !important;
}

.sv_qstn .sv_q_imgsel label > div > img, .sv_qstn .sv_q_imgsel label > div > embed {
  display: block;
  width: 100%;
  height: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* body {background-color: red;} */
  .parentFrame {
    height: 800px
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* body {background-color: green;} */
  .parentFrame {
    height: 800px
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* body {background-color: blue;} */
  .parentFrame {
    height: 800px
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
   /* body {background-color: orange;} */
   .parentFrame {
    height: 800px
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 900px) {
  /* body {
    background-color: pink;
  } */
  .parentFrame {
    height: 800px
  }
}  

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  /* body {
    background-color: yellow;
  } */
  .parentFrame {
    height: 900px
  }
}  
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
